import { graphql, useStaticQuery } from "gatsby"
import {
  filterDataByLanguage,
  getRichTextData,
  getVideos,
} from "./serviceHelpers"

export const useVideoSchoolData = lang => {
  const { allKontentItemSchoolComponent } = useStaticQuery(
    graphql`
      query VideoSchoolQuery {
        allKontentItemSchoolComponent {
          nodes {
            elements {
              video_header {
                resolvedData {
                  html
                }
              }
              list_of_videos {
                linked_items {
                  elements {
                    video_description {
                      value
                    }
                    video_header {
                      value
                    }
                    video_url {
                      value
                    }
                  }
                }
              }
            }
            preferred_language
          }
        }
      }
    `
  )

  const QueryData = filterDataByLanguage(allKontentItemSchoolComponent, lang)

  let returnData = {
    header: getRichTextData(QueryData.video_header),
    videos: getVideos(QueryData.list_of_videos),
  }

  return returnData
}
