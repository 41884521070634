import React from "react"
import HeroHeader from "../../components/blocks/HeroHeader/HeroHeader"
import BenefitGroup from "./BenefitGroup/BenefitGroup"
import styles from "./BenefitsView.module.scss"



const BenefitsView = props => {
  const { header, groups} = props
  return (
  <section className={styles.benefitsClass}>

  <HeroHeader>{header}</HeroHeader>
<div className={styles.groupWrapper}>

  {groups && groups.map((group, i) => <BenefitGroup image={group.image} header={group.header} adventages={group.adventages} key={i} />)}
</div>
 </section>
  )
}

export default BenefitsView
