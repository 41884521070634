import React from "react"
import styles from "./CountedElements.module.scss"

const CountedElements = ({ children, withoutBullets }) => (
  <div
    className={withoutBullets ? styles.countedElements : styles.countedElementsWithBullets}
    dangerouslySetInnerHTML={{ __html: children }}
  />
)

export default CountedElements
