import axios from "axios"
import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import Loader from "react-loader-spinner"

import Button from "../../blocks/ButtonComponent/ButtonComponent"
import ErrorTextInput from "../../blocks/ErrorTextInput/ErrorTextInput"
import FormControl from "../../blocks/FormControl/FormControl"
import styles from "./ContactFormSchool.module.scss"

const ContactFormSchool = React.memo(
  ({ contactFormData, URLHistory, langActive }) => {
    const [email, setEmail] = useState("")
    const [companySize, setCompanySize] = useState("")
    const [message, setMessage] = useState("")
    const [mainAgreement, setMainAgreement] = useState("Nie")
    // const [mailAgreement, setMailAgreement] = useState("Nie")
    // const [phoneAgreement, setPhoneAgreement] = useState("Nie")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [sendEmailSucceed, setSendEmailSucceed] = useState(false)
    const [showApprove, setShowApprove] = useState(false)
    const postEndpoint =
      "https://prod-151.westeurope.logic.azure.com:443/workflows/d42891c190ca4800901ce90463960f8f/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Xs7tZ8rhs4bLWKAQcmbzg6_i2ZKmKtj2WZjDYS3NG2o"

    const submitHandler = async event => {
      event.preventDefault()
      setLoading(true)
      const { previousURL, currentURL, referrerURL } = URLHistory
      const checkReferrer = !previousURL ? "no previous URL" : previousURL

      const data = {
        companySize: companySize,
        email: email,
        mailAgreement: mainAgreement,
        // phoneAgreement: phoneAgreement,
        message: message,
        source: referrerURL,
        url: currentURL,
        referrer: checkReferrer,
      }

      try {
        await axios.post(postEndpoint, { ...data })
        setLoading(false)
        resetValues()
        setSendEmailSucceed(true)
      } catch (err) {
        setLoading(false)
        setError(true)
      }
    }

    const inputHandler = (id, value) => {
      let convertedValue
      setShowApprove(true)
      setError(false)
      switch (id) {
        case "contact-email":
          setEmail(value)
          break
        case "contact-company-size":
          setCompanySize(value)
          break
        case "message":
          setMessage(value)
          break
        // useless for now
        //  case "mail-agreement":
        //   value === true ? (convertedValue = "Tak") : (convertedValue = "Nie")
        //   setMailAgreement(convertedValue)
        //   break
        // case "phone-agreement":
        //   value === true ? (convertedValue = "Tak") : (convertedValue = "Nie")
        //   setPhoneAgreement(convertedValue)
        //   break
        case "main-agreement":
          value === true ? (convertedValue = "Tak") : (convertedValue = "Nie")
          setMainAgreement(convertedValue)
          break
        default:
          break
      }
    }

    const resetValues = () => {
      setEmail("")
      setCompanySize("")
      setMessage("")
      setMainAgreement(false)
      // setMailAgreement(false)
      // setPhoneAgreement(false)
    }

    const errorHandler = () => {
      if (mainAgreement === "Nie") {
        setError(true)
        return
      }
    }

    const validationErrorMsg =
      langActive === "pl"
        ? "Te pola są wymagane!"
        : "These fields are required!"
    const emailSendMsg =
      langActive === "pl" ? "Dziękujemy!" : "Thank you e-mail has been sent"
    const errorMsg =
      langActive === "pl" ? "Coś poszło nie tak" : "Something went wrong"

    return (
      <form className={styles.ContactForm} onSubmit={submitHandler}>
        {contactFormData && (
          <FormControl
            control="input"
            type="email"
            id="contact-email"
            required={true}
            placeholder={`${contactFormData.formMailPlh}*`}
            action={inputHandler}
            value={email}
          />
        )}
        {contactFormData && (
          <FormControl
            control="select"
            id="contact-company-size"
            defaultValue={`${contactFormData.companySizePlh}*`}
            options={[
              {
                label: `${contactFormData.companyContainer[0]}`,
                Value: "podstawowa",
              },
              {
                label: `${contactFormData.companyContainer[1]}`,
                Value: "ponadpodstawowa",
              },
              {
                label: `${contactFormData.companyContainer[2]}`,
                Value: "inna",
              },
            ]}
            required={true}
            action={inputHandler}
            value={companySize}
          />
        )}
        {contactFormData && (
          <FormControl
            control="textarea"
            id="message"
            rows={10}
            placeholder={`${contactFormData.messagePlh}*`}
            action={inputHandler}
            value={message}
            required={true}
          />
        )}
        <Fade when={showApprove} top>
          {contactFormData && (
            <FormControl
              control="checkbox"
              id="main-agreement"
              type="checkbox"
              label={`${contactFormData.mainAgreent}`}
              action={inputHandler}
              required={true}
              value={mainAgreement}
              styleProps={
                error && mainAgreement === "Nie"
                  ? { border: "1px solid red" }
                  : null
              }
              error={error && mainAgreement === "Nie"}
              errorMsg={validationErrorMsg}
            />
          )}
        </Fade>
        {/* <Fade when={showApprove} top>     
{contactFormData && contactFormData.firstAgreement && showApprove && 
        <FormControl
              control="checkbox"
              id="mail-agreement"
              type="checkbox"
              label={`${contactFormData.firstAgreement}`}
              action={inputHandler}
              required={true}
              value={mailAgreement}
              styleProps={
                error && mailAgreement === "Nie"
                  ? { border: "1px solid red" }
                  : null
              }
              error={error && mailAgreement === "Nie"}
              errorMsg={validationErrorMsg}
            />}
            </Fade>  
        <Fade when={showApprove} top>
       {contactFormData && contactFormData.secondAgreement && showApprove && <FormControl
        control="checkbox"
        id="phone-agreement"
        type="checkbox"
        label={`${contactFormData.secondAgreement}`}
        action={inputHandler}
        value={phoneAgreement}
        error={error && phoneAgreement === "Nie"}
              errorMsg={validationErrorMsg}
      />}
              </Fade> */}
        {!loading ? (
          <div className={styles.buttonWrapper}>
            {contactFormData && contactFormData.buttonPlh && (
              <Button
                styleProps={
                  sendEmailSucceed
                    ? {
                        maxWidth: "330px",
                        margin: "0",
                        backgroundColor: "#258373",
                      }
                    : { maxWidth: "330px", margin: "0" }
                }
                buttonData={{ name: `${contactFormData.buttonPlh}` }}
                buttonDataEmailSend={emailSendMsg}
                emailSend={sendEmailSucceed}
                action={errorHandler}
                type="submit"
              />
            )}
          </div>
        ) : (
          <div className={styles.Loader}>
            <Loader type="Oval" color="#08b5c7" height={50} width={50} />
          </div>
        )}
        {error && mainAgreement !== "Nie" ? (
          <ErrorTextInput>{errorMsg}</ErrorTextInput>
        ) : null}
      </form>
    )
  }
)

export default ContactFormSchool
