import React from "react"
import styles from "./BenefitGroup.module.scss"

import CountedElements from "../../../components/blocks/CountedElements/CountedElements"
import RichTextInput from "../../../components/blocks/RichTextInput/RichTextInput"

export const BenefitGroup = props => {
    const { image, header, adventages } = props
   return (
  <div className={styles.benefitGroup}>
    <img src={image} alt="Benefit group"/>
    <RichTextInput>{header}</RichTextInput>
    <CountedElements withoutBullets>{adventages}</CountedElements>
  </div>
)}


export default BenefitGroup