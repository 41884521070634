import { graphql, useStaticQuery } from "gatsby"
import {
  filterDataByLanguage,
  getRichTextData,
  getAssets
} from "./serviceHelpers"

export const useMotivationSchoolData = lang => {
  const { allKontentItemSchoolComponent } = useStaticQuery(
    graphql`
      query motivationSchoolQuery {
        allKontentItemSchoolComponent {
          nodes {
            elements {
              motivation_header {
                resolvedData {
                  html
                }
              }
              motivation_pointers {
                resolvedData {
                  html
                }
              }
              integration_text {
                resolvedData {
                  html
                }
              }
              firm_list {
                value {
                  url
                }
              }
            }
            preferred_language
          }
        }
      }
    `
  )

  const QueryData = filterDataByLanguage(allKontentItemSchoolComponent, lang)

  let returnData = {
    header: getRichTextData(QueryData.motivation_header),
    pointers: getRichTextData(QueryData.motivation_pointers),
    integrationText: getRichTextData(QueryData.integration_text),
    firmList: getAssets(QueryData.firm_list)
  }

  return returnData
}
