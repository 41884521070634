import { graphql, useStaticQuery } from "gatsby"
import {
  filterDataByLanguage,
  getRichTextData,
  getAssetData,
} from "./serviceHelpers"

export const useHowItWorksSchoolData = lang => {
  const { allKontentItemSchoolComponent } = useStaticQuery(
    graphql`
      query howItWorkSchoolQuery {
        allKontentItemSchoolComponent {
          nodes {
            elements {
              how_it_works_decription {
                resolvedData {
                  html
                }
              }
              how_it_works_header {
                resolvedData {
                  html
                }
              }
              how_it_works_asset {
                value {
                  url
                }
              }
            }
            preferred_language
          }
        }
      }
    `
  )

  const QueryData = filterDataByLanguage(allKontentItemSchoolComponent, lang)

  let returnData = {
    header: getRichTextData(QueryData.how_it_works_header),
    description: getRichTextData(QueryData.how_it_works_decription),
    asset: getAssetData(QueryData.how_it_works_asset)
  }

  return returnData
}
