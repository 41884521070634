import React from "react"
import CountedElements from "../../components/blocks/CountedElements/CountedElements"
import Header from "../../components/blocks/Header/Header"
import SmallRichTextInput from "../../components/blocks/SmallRichTextInput/SmallRichTextInput"
import styles from "./MotivationSchoolView.module.scss"



const MotivationSchoolView = props => {
  const { header, integrationText, pointers, firmList} = props
  return (
  <div className={styles.motivationClass}>
  <section className={styles.wrapper}>
  <div className={styles.motivationWrapper}>
  <span><Header>{header}</Header>  </span>
  <CountedElements>{pointers}</CountedElements>

  </div> 
  <div className={styles.cooperationWrapper}>
  <div className={styles.imgContainer}>
  {firmList&& firmList.map((url, i) =>
    <img key={i} src={url} className={styles.img} alt="Company"/> 
  )}
  </div>
  <SmallRichTextInput>
{integrationText}
</SmallRichTextInput>  
 </div>
 </section>
 </div>
  )
}

export default MotivationSchoolView
