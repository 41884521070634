import React from "react"
import styles from "./HeroHeader.module.scss"

const HeroHeader = ({ children }) => (
  <h1
    dangerouslySetInnerHTML={{ __html: children }}
    className={styles.HeroHeader}
  />
)

export default HeroHeader
