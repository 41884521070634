import React from "react"
import CountedElements from "../../components/blocks/CountedElements/CountedElements"
import Header from "../../components/blocks/Header/Header"
import styles from "./HowItWorksView.module.scss"



const HowItWorksView = props => {
  const { header, description, asset} = props
  return (
  <div className={styles.howItWorksClass}>
  <section className={styles.wrapper}>
  <img src={asset} className={styles.img} alt="group"/>
  <div className={styles.textWrapper}>
  <Header>{header}</Header>
  <CountedElements>{description}</CountedElements>
  </div> 
  </section>
 </div>
  )
}

export default HowItWorksView
