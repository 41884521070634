import React from "react"

import LocalizedLink from "../LocalizedLink/LocalizedLink"

import styles from "./ButtonComponent.module.scss"

const ButtonComponent = ({
  buttonData,
  styleProps,
  action,
  type,
  buttonDataEmailSend,
  emailSend,
}) => {

  if (action) {
    return (
      <button
        className={
          buttonData && buttonData.buttonStyle === "Secondary"
            ? styles.ButtonStyleSecondary
            : styles.ButtonStylePrimary
        }
        style={styleProps}
        onClick={action}
        type={type}
      >
        {emailSend ? buttonDataEmailSend : buttonData && buttonData.name}
      </button>
    )
  } else if (buttonData && buttonData.link) {
    return (
      <LocalizedLink to={buttonData && buttonData.link}>
        <button
          className={
            buttonData && buttonData.buttonStyle === "Secondary"
              ? styles.ButtonStyleSecondary
              : styles.ButtonStylePrimary
          }
          style={styleProps}
        >
          {buttonData && buttonData.name}
        </button>
      </LocalizedLink>
    )
  } else if (buttonData && buttonData.slug) {
    return (
      <LocalizedLink to={`/${buttonData && buttonData.slug}`}>
        <button
          className={
            buttonData && buttonData.buttonStyle === "Secondary"
              ? styles.ButtonStyleSecondary
              : styles.ButtonStylePrimary
          }
          style={styleProps}
        >
          {buttonData && buttonData.name}
        </button>
      </LocalizedLink>
    )
  } else {
    return (
      <button
        className={
          buttonData && buttonData.buttonStyle === "Secondary"
            ? styles.ButtonStyleSecondary
            : styles.ButtonStylePrimary
        }
        style={styleProps}
        type={type}
      >
        {buttonData && buttonData.name}
      </button>
    )
  }
}
export default ButtonComponent
