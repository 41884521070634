import React from "react"
import Header from "../../components/blocks/Header/Header"
import TextInput from "../../components/blocks/TextInput/TextInput"
import ContactFormSchool from "../../components/containers/ContactFormSchool/ContactFormSchool"
import styles from "./ContactSchoolView.module.scss"





const ContactSchoolView = props => {
  const { contactHeader, completeFormPlh  } = props

  return (
  <section id='skontaktuj-sie' className={styles.contacSchoolView}>
  <div className={styles.contactWrapper}>
  <Header>{contactHeader}</Header>
  <TextInput styleProps={{margin: "0"}}>{completeFormPlh}</TextInput>
<ContactFormSchool {...props} />
{/* commented for now
 <XsTextInput >{administrationPolicy}</XsTextInput> */}
</div>
 </section>
  )
}

export default ContactSchoolView
