import React from "react"

import CheckboxTextInput from "../CheckboxTextInput/CheckboxTextInput"
import ErrorTextInput from "../../blocks/ErrorTextInput/ErrorTextInput"
import styles from "./FormControl.module.scss"

const FormControl = ({
  control,
  type,
  id,
  required,
  placeholder,
  options,
  defaultValue,
  label,
  rows,
  action,
  value,
  styleProps,
  error,
  errorMsg,
}) => (
  <div>
    {control === "input" && (
      <input
        className={`${styles.Input} ${styles.FormControl}`}
        type={type}
        id={id}
        required={required}
        placeholder={placeholder}
        onChange={({ target }) => action(id, target.value)}
        style={{ styleProps }}
        value={value}
      />
    )}
    {control === "checkbox" && (
      <div className={styles.CheckboxContainer}>
        <label className={styles.CheckboxWrapper}>
          <input
            type={type}
            id={id}
            required={required}
            placeholder={placeholder}
            onChange={({ target }) => action(id, target.checked)}
            defaultValue={value === "Tak" ? true : false}
            checked={value === "Tak" ? true : false}
          />
          <div style={styleProps} className={`${styles.Checkbox}`}></div>
          <CheckboxTextInput>{label}</CheckboxTextInput>
        </label>
        {error && (
          <ErrorTextInput styleProps={{ marginLeft: "30px" }}>
            {errorMsg}
          </ErrorTextInput>
        )}
      </div>
    )}
    {control === "select" && (
      <select
        className={`${styles.Select} ${styles.FormControl}`}
        id={id}
        required={required}
        onChange={({ target }) => action(id, target.value)}
        style={{ styleProps }}
        value={value}
      >
        <option value="" selected disabled>
          {defaultValue}
        </option>
        {options.map(({ label, value }, index) => (
          <option value={value} key={index}>
            {label}
          </option>
        ))}
      </select>
    )}
    {control === "textarea" && (
      <textarea
        className={`${styles.Textarea} ${styles.FormControl}`}
        id={id}
        rows={rows}
        placeholder={placeholder}
        onChange={({ target }) => action(id, target.value)}
        style={{ styleProps }}
        required={required}
        value={value}
      ></textarea>
    )}
  </div>
)

export default FormControl
