import React, { useContext } from "react"
import Helmet from "react-helmet"

import { LangContext } from "../../context/LangContext"
import { useWindowScroll } from "../../hooks/useWindowScroll"

import { useNavigationSchoolData } from "../../service/navigation-school.service"
import { useHeroSchoolData } from "../../service/hero-school.service"
import { useMotivationSchoolData } from "../../service/motivation-school.service"
import { useHowItWorksSchoolData } from "../../service/how-it-work.service"
import { useBenefitSchoolData } from "../../service/benefit-school.service"
import { useVideoSchoolData } from "../../service/video-school.service"
import { useContactSchoolData } from "../../service/contact-school.service"
import { useFaqSchoolData } from "../../service/faq-school.service"
import { useGlobalMetaTags } from "../../service/global-meta-tags.service"
import { renderGlobalMetaTags } from "../../utils/renderGlobalMetaTags"

import BenefitsView from "../../views/BenefitsView/BenefitsView"
import ContactSchoolView from "../../views/ContactSchoolView/ContactSchoolView"
import FaqSchoolView from "../../views/FaqSchoolView/FaqSchoolView"
import HeroSchoolView from "../../views/HeroSchoolView/HeroSchoolView"
import HowItWorksView from "../../views/HowItWorksView/HowItWorksView"
import MotivationSchoolView from "../../views/MotivationSchoolView/MotivationSchoolView"
import Navigation from "../../components/containers/Naviagtion/Navigation"
import OGSchoolImage from "../../images/og-image-school.jpg"
import VideosView from "../../views/VideosView/VideosView"

import styles from "../szkola.module.scss"

const KenticoSchoolData = lang => {
  return {
    navigationSchoolViewData: useNavigationSchoolData(lang),
    heroSchoolData: useHeroSchoolData(lang),
    motivationData: useMotivationSchoolData(lang),
    howItWorksData: useHowItWorksSchoolData(lang),
    benefitData: useBenefitSchoolData(lang),
    videosData: useVideoSchoolData(lang),
    faqViewData: useFaqSchoolData(lang),
    contactSchoolViewData: useContactSchoolData(lang),
  }
}

const SchoolPage = props => {
  const isScrolledFromTop = useWindowScroll()
  const { languages, pageLang, toggleLang } = useContext(LangContext)

  const {
    navigationSchoolViewData,
    heroSchoolData,
    motivationData,
    howItWorksData,
    benefitData,
    videosData,
    faqViewData,
    contactSchoolViewData,
    URLHistory,
  } = props

  const metaTitle =
    pageLang === "pl"
      ? "Grow Uperion - sprawdzona platforma grywalizacyjna dla szkół"
      : "Grow Uperion - sprawdzona platforma grywalizacyjna dla szkół "
  const metaDescription =
    pageLang === "pl"
      ? "Wzmacnia zaangażowanie uczniów, pomaga rodzicom pracującym w domu, ułatwia pracę nauczycielom."
      : "Wzmacnia zaangażowanie uczniów, pomaga rodzicom pracującym w domu, ułatwia pracę nauczycielom."

  const globalMetaTags = useGlobalMetaTags(pageLang)

  return (
    <React.Fragment>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content={"http://growuperion.com/szkola"} />
        <meta property="og:image" content={OGSchoolImage} />
        {renderGlobalMetaTags(globalMetaTags)}
      </Helmet>
      <section className={styles.SchoolContainer}>
        <div
          className={
            isScrolledFromTop
              ? `${styles.HeaderWrapper} ${styles.Scrolled}`
              : `${styles.HeaderWrapper}`
          }
        >
          <Navigation
            elements={
              navigationSchoolViewData
                ? navigationSchoolViewData.navElements
                : []
            }
            scrolled={isScrolledFromTop}
            buttonSlug={
              navigationSchoolViewData
                ? navigationSchoolViewData.conversionComponent
                : ""
            }
            logo={navigationSchoolViewData ? navigationSchoolViewData.logo : ""}
            langActive={pageLang}
            changeLang={toggleLang}
            langs={languages}
            alignLeft={true}
          />
        </div>
        <HeroSchoolView {...heroSchoolData} />
        <MotivationSchoolView {...motivationData} />
        <HowItWorksView {...howItWorksData} />
        <BenefitsView {...benefitData} />
        <VideosView {...videosData} />
        <FaqSchoolView {...faqViewData} />
        <ContactSchoolView
          {...contactSchoolViewData}
          langs={languages}
          URLHistory={URLHistory}
        />
      </section>
    </React.Fragment>
  )
}

export default () => {
  const { pageLang } = useContext(LangContext)
  const data = KenticoSchoolData(pageLang)
  return <SchoolPage {...data} />
}
