import { graphql, useStaticQuery } from "gatsby"
import {
  filterDataByLanguage,
  getRichTextData,
  getTextData,
} from "./serviceHelpers"

export const useHeroSchoolData = lang => {
  const { allKontentItemSchoolComponent } = useStaticQuery(
    graphql`
      query heroSchoolQuery {
        allKontentItemSchoolComponent {
          nodes {
            elements {
              hero_description {
                value
              }
              hero_header {
                resolvedData {
                  html
                }
              }
            }
            preferred_language
          }
        }
      }
    `
  )

  const heroSchoolQueryData = filterDataByLanguage(allKontentItemSchoolComponent, lang)

  let heroSchoolData = {
    header: getRichTextData(heroSchoolQueryData.hero_header),
    description: getTextData(heroSchoolQueryData.hero_description),
  }

  return heroSchoolData
}
