import React from "react"
import FAQitem from "../../components/blocks/FAQitem/FAQitem"
import Header from "../../components/blocks/Header/Header"
import styles from "./FaqSchoolView.module.scss"



const FaqSchoolView = props => {
  const { header, faqPoints, bulletPoint} = props
  return (
  <div id="faq" className={styles.faqClass}>
  <section className={styles.wrapper}>
  <Header>{header}</Header>
  {faqPoints && faqPoints.map((faq, i)=> <FAQitem key={i} {...faq} bulletPoint={bulletPoint} />)}
 </section>
 </div>
  )
}

export default FaqSchoolView
