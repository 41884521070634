import { graphql, useStaticQuery } from "gatsby"
import {
  filterDataByLanguage,
  getButtonData,
  getNavLinks,
  getPictureUrl,
} from "./serviceHelpers"
import get from "lodash/get"

export const useNavigationSchoolData = lang => {
  const { allKontentItemSchoolComponent } = useStaticQuery(
    graphql`
      query NavigationSchoolQuery {
        allKontentItemSchoolComponent {
          nodes {
            elements {
              navigation_conversion_component {
                linked_items {
                  elements {
                    button_style {
                      value {
                        name
                      }
                    }
                    button_text {
                      value
                    }
                    slug_to_element {
                      value
                    }
                  }
                }
              }
              navigation_links {
                linked_items {
                  elements {
                    navigation_slug {
                      value
                    }
                    navigation_text {
                      value
                    }
                  }
                }
              }
              logo {
                value {
                  url
                }
              }
            }
            preferred_language
          }
        }
      }
    `
  )

  const navigationQueryData = filterDataByLanguage(
    allKontentItemSchoolComponent,
    lang
  )

  let navSchoolData = {
    logo: getPictureUrl(get(navigationQueryData, "logo", "")),
    navElements: getNavLinks(get(navigationQueryData, "navigation_links", [])),
    conversionComponent: getButtonData(get(navigationQueryData,"navigation_conversion_component",null)),
  }

  return navSchoolData
}
