import { graphql, useStaticQuery } from "gatsby"
import { filterDataByLanguage, getCompanySizeData, getRichTextData, getTextData } from "./serviceHelpers"
export const useContactSchoolData = lang => {
  const { allKontentItemSchoolComponent } = useStaticQuery(
    graphql`
      query contactSchoolQuery {
        allKontentItemSchoolComponent {
          nodes {
            preferred_language
            elements {
              contact_header {
                resolvedData {
                  html
                }
              }
              complete_form_placeholder {
                value
              }
              form_warning_information {
                value
              }
              email_placeholder {
                value
              }
              company_size_placeholder {
                value
              }
              company_size_container {
                linked_items {
                  elements {
                    company_size_text {
                      value
                    }
                  }
                }
              }
              main_policy {
                value
              }
              policy_agreement {
                value
              }
              policy_agreement2 {
                value
              }
              button_placeholder {
                value
              }
              message_placeholder {
                value
              }
              administration_policy {
                value
              }
            }
          }
        }
      }
    `
  )

  const contactQueryData = filterDataByLanguage(
    allKontentItemSchoolComponent,
    lang
  )

  let contactData = {
    contactHeader: getRichTextData(contactQueryData.contact_header),
    warningInformation: getTextData(contactQueryData.form_warning_information),
    completeFormPlh: getTextData(contactQueryData.complete_form_placeholder),
    administrationPolicy:  getTextData(contactQueryData.administration_policy),
    contactFormData: {
      formMailPlh: getTextData(contactQueryData.email_placeholder),
      companySizePlh: getTextData(contactQueryData.company_size_placeholder),
      companyContainer: getCompanySizeData(
        contactQueryData.company_size_container
      ),
      messagePlh: getTextData(contactQueryData.message_placeholder),
      mainAgreent: getTextData(contactQueryData.main_policy),
      // firstAgreement: getTextData(contactQueryData.policy_agreement),
      // secondAgreement: getTextData(contactQueryData.policy_agreement2),
      buttonPlh: getTextData(contactQueryData.button_placeholder),
    },

  }

  return contactData
}
