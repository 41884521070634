import React from "react"
import styles from "./SmallRichTextInput.module.scss"

  const SmallRichTextInput = ({ children, styleProps }) => (
    <span
      dangerouslySetInnerHTML={{ __html: children }}
      className={styles.smallTextImput}
      style={styleProps ? styleProps : null}
    />
  )

export default SmallRichTextInput
