import React from "react"
import styles from "./VideoCard.module.scss"
import VideoComponent from "../../../components/blocks/VideoComponent/VideoComponent"

const VideoCard = props => {
  const { videoUrl, header, description } = props
  return (
    <div className={styles.videoClass}>
      <VideoComponent videoSrc={videoUrl} />
      <div className={styles.wrapper}>
        <div className={styles.header}>{header}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  )
}

export default VideoCard
