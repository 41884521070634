import React from "react"
import styles from "./VideoComponent.module.scss"

const VideoComponent = ({ videoSrc }) => {
  return (
  <iframe
    className={styles.video}
    src={videoSrc}
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    iv_load_policy="3"
    modestbranding="true"
    rel="0"
    frameBorder="0"
    title="youtube video"
    allowFullScreen
  ></iframe>
)}

export default VideoComponent
