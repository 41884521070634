import React from "react"
import HeroAsset from '../../assets/school-hero-asset.svg'
import Header from "../../components/blocks/Header/Header"
import TextInput from "../../components/blocks/TextInput/TextInput"
import styles from "./HeroSchoolView.module.scss"



const HeroSchoolView = props => {
  const { header, description } = props
  return (
  <div className={styles.wrapper}>
<HeroAsset className={styles.img}/>
          <div className={styles.textContainer}>
          <Header>{header}</Header>
          <TextInput>{description}</TextInput>
          </div>
  </div>
  )
}

export default HeroSchoolView
