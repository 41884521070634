import React, { useState } from "react"
import styles from "./FAQitem.module.scss"

const FAQitem = ({ question, answer, bulletPoint }) => {
  const [isActive, setActiveState] = useState(false)

  return (
    <div className={styles.faqWrapper}>
      <div className={styles.questionWrapper}>
      <button
          className={styles.question}
          onClick={() => setActiveState(!isActive)}
        >
        <img
          src={bulletPoint}
          className={isActive ? styles.figureActive : styles.figureNonActive}
          alt="Bullet point"
        />
              </button>
        <button
          className={styles.question}
          onClick={() => setActiveState(!isActive)}
        >
          {question}
        </button>
      </div>
      {isActive && <span className={styles.answer} dangerouslySetInnerHTML={{ __html: answer }} />}
    </div>
  )
}

export default FAQitem
