import React from "react"
import styles from "./ErrorTextInput.module.scss"

const ErrorTextInput = ({ children, styleProps }) => (
  <p className={styles.ErrorTextInput} style={styleProps ? styleProps : null}>
    {children}
  </p>
)

export default ErrorTextInput
