import { graphql, useStaticQuery } from "gatsby"
import {
  filterDataByLanguage,
  getRichTextData,
  getTextData,
  getAssetData
} from "./serviceHelpers"

export const useBenefitSchoolData = lang => {
  const { allKontentItemSchoolComponent } = useStaticQuery(
    graphql`
      query benefitSchoolQuery {
        allKontentItemSchoolComponent {
          nodes {
            elements {
              third_group_header {
                resolvedData {
                  html
                }
              }
              third_group_adventages {
                resolvedData {
                  html
                }
              }
              first_group_header {
                resolvedData {
                  html
                }
              }
              first_group_adventages {
                resolvedData {
                  html
                }
              }
              benefit_section_header {
                value
              }
              third_group_asset {
                value {
                  url
                }
              }
              second_group_header {
                resolvedData {
                  html
                }
              }
              second_group_asset {
                value {
                  url
                }
              }
              second_group_adventages {
                resolvedData {
                  html
                }
              }
              first_group_asset {
                value {
                  url
                }
              }
            }
            preferred_language
          }
        }
      }
    `
  )

  const QueryData = filterDataByLanguage(allKontentItemSchoolComponent, lang)

  let returnData = {
    header: getTextData(QueryData.benefit_section_header),
   groups: [
    {
      image: getAssetData(QueryData.first_group_asset),
      header: getRichTextData(QueryData.first_group_header),
      adventages: getRichTextData(QueryData.first_group_adventages),
    },
    {
      image: getAssetData(QueryData.second_group_asset),
      header: getRichTextData(QueryData.second_group_header),
      adventages: getRichTextData(QueryData.second_group_adventages),
    },
    {
      image: getAssetData(QueryData.third_group_asset),
      header: getRichTextData(QueryData.third_group_header),
      adventages: getRichTextData(QueryData.third_group_adventages),
    }
   ]  
  }

  return returnData
}
