import React from "react"
import styles from "./VideosView.module.scss"
import VideoCard from "./VideoComponent/VideoCard"
import Header from "../../components/blocks/Header/Header"





const VideosView = props => {
  const { header, videos} = props
  return (
  <section id='video' className={styles.videoClass}>
  <Header>{header}</Header>
  <div className={styles.videosWrapper}>
  {videos && videos.map((video, i) => <VideoCard {...video} key={i} />)}
  </div> 
 </section>
  )
}

export default VideosView
