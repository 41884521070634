import React from "react"
import styles from "./CheckboxTextInput.module.scss"

const CheckboxTextInput = ({ children, styleProps }) => (
  <p className={styles.CheckboxTextInput} style={styleProps ? styleProps : null}>
    {children}
  </p>
)

export default CheckboxTextInput
